<template>
  <div>
    <div class="back-icon" @click="$router.go(-1)">
      <img src="../../assets/images/goback.png" />
      学习测试
    </div>
    <div class="select-box">
      <span>
        <span>所属公司：</span>
        <el-select
          v-model="company"
          clearable
          placeholder="请选择"
          @change="changCompany"
        >
          <el-option
            v-for="item in companyList"
            :key="item.value"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </span>
      <span style="margin: 10px">
        <span>所属部门：</span>
        <el-select
          v-model="dept"
          clearable
          @change="changDept"
          placeholder="请选择"
        >
          <el-option
            v-for="item in deptList"
            :key="item.value"
            :label="item.deptmentName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </span>
      <span style="margin: 10px">
        <span>所属岗位：</span>
        <el-select
          v-model="post"
          clearable
          placeholder="请选择"
          @change="changPost"
        >
          <el-option
            v-for="item in postList"
            :key="item.value"
            :label="item.postName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </span>
      <el-button icon="el-icon-search"></el-button>
      <img src="../../assets/images/learn3.png" />
      <el-button type="primary" @click="selectNum" class="btn">
        获取抽题数
      </el-button>
    </div>
    <div class="_TableBox">
      <el-table :data="tableData" style="width: 100%" :height="elementHeight">
        <el-table-column prop="index" label="序号" width="80">
        </el-table-column>
        <el-table-column prop="date" label="文件名称" width="180">
        </el-table-column>
        <el-table-column prop="name" label="问题" width="180">
        </el-table-column>
        <el-table-column prop="address" label="标准答案"> </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        class="_Pagination"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="抽题"
      :visible.sync="drawDialog"
      width="30%"
      top="25vh"
      class="draw-dialog"
    >
      <div class="select">
        <span style="margin-right: 10px">文件名称</span>
        <el-select
          class="choose"
          v-model="checkFileList"
          multiple
          placeholder="请选择"
        >
          <el-option
            v-for="item in fliesList"
            :key="item.value"
            :label="item.fileName"
            :value="item.fileNo"
          >
          </el-option>
        </el-select>
      </div>
      <div class="inp">
        <span>抽取题目</span>
        <div>
          <div class="draw draw1">
            <span>文件总数</span>
            <span>剩余文件数</span>
            <span>抽取数</span>
          </div>
          <div class="draw draw2">
            <span>无绑定</span>
            <span>无绑定</span>
            <div class="operating">
              <input type="text" v-model="drawncount" @input="inputInp" />
            </div>
          </div>
        </div>
        <!-- <span>共 {{ this.count }} 道题，抽取题目数</span
        ><span class="btn" @click="handleDrawnNumber(0)">―</span
        ><input type="text" v-model="drawncount" @input="inputInp" /><span
          class="btn"
          @click="handleDrawnNumber(1)"
          >+</span
        > -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="deawQuestion">确定抽题</el-button>
        <el-button @click="drawDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from "../../api/http";
export default {
  data() {
    return {
      elementHeight: 0,
      tableData: [],
      currentPage: 1,
      company: null,
      companyList: [],
      dept: null,
      deptList: [],
      post: null,
      postList: [],
      checkFileList: [],
      drawncount: 0,
      count: 0,
      fliesList: [],
      drawDialog: false,
    };
  },
  mounted() {
    this.getElementHeight();
    this.companyList = this.$store.state.companyUserList;
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 30 + 60 + 70); //70是div底部距离底部的高度
      });
    },
    handleSizeChange() {},
    handleCurrentChange() {},
    getDeptList() {
      get("/api/Department/GetNoTree?CompId=" + this.company).then((resp) => {
        if (resp.code == 200) {
          this.dept = "";
          this.deptList = resp.data;
        }
      });
    },
    getPost() {
      get("/api/Post?CompId=" + this.company + "&DeptId=" + this.dept).then(
        (res) => {
          this.postList = res.data;
        }
      );
    },
    getFliesList() {
      get(
        "/api/StudyByPost/GetFileList?CompId=" +
          this.company +
          "&DeptId=" +
          this.dept +
          "&PostId=" +
          this.post
      ).then((res) => {
        this.fliesList = res.data;
      });
    },
    changCompany(e) {
      this.company = e;
      if (e) this.getDeptList();
    },
    changDept(e) {
      this.dept = e;
      this.post = "";
      this.checkFileList = [];
      if (e) this.getPost();
    },
    changPost(e) {
      if (!e) return;
      this.getFliesList();
      this.post = e;
    },

    // 抽题
    selectNum() {
      if (!this.post) {
        this.$message({
          type: "warning",
          message: "未选择公司/部门/岗位",
        });
        return;
      }
      this.getQuestion();
    },
    getQuestion() {
      var param =
        "?CompId=" +
        this.company +
        "&DeptId=" +
        this.dept +
        "&PostId=" +
        this.post;
      var list = [];
      var item = {};
      this.fliesList.map((i) => {
        this.checkFileList.map((e) => {
          if (i.fileNo == e) {
            this.pushJsonItem(item, list, i);
          }
        });
      });
      list = "[" + list + "]";
      var ReqHeader =
        this.checkFileList.length == 0
          ? get("/api/StudyByPost/GetPostCount" + param)
          : post("/api/StudyByPost/GetByFilesCount" + param, list);
      ReqHeader.then((resp) => {
        if (resp.code == 200) {
          this.count = resp.count;
          this.drawncount = resp.count;
          this.drawDialog = true;
        } else {
          this.$message.error(resp.message);
        }
      });
    },
    deawQuestion() {
      var url = "";
      var list = [];
      var item = {};
      var reqHeader = function () {};
      var param =
        "?CompId=" +
        this.company +
        "&DeptId=" +
        this.dept +
        "&PostId=" +
        this.post +
        "&QuesNo=" +
        this.drawncount;
      if (this.checkFileList.length == 0) {
        url = "/api/StudyByPost/GetQuesByRandom";
        reqHeader = post(url + param);
      } else {
        this.fliesList.map((i) => {
          this.checkFileList.map((e) => {
            if (i.fileNo == e) {
              this.pushJsonItem(item, list, i);
            }
          });
        });
        list = "[" + list + "]";
        url = "/api/StudyByPost/PostByFiles";
        reqHeader = post(url + param, list);
      }
      reqHeader
        .then((resp) => {
          if (resp.code == 200) {
            if (
              resp.message == "您已经完成文件学习！如需重新学习，请点击重新学习"
            ) {
              this.fileDialog = true;
              return;
            }
            resp.data.map((item) => {
              item.lean = "未学习";
            });
            this.tableData = resp.data;
            this.drawDialog = false;
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("获取失败");
        });
    },
    inputInp() {
      if (this.drawncount > this.count) this.drawncount = 0;
      if (this.drawncount < 0) this.drawncount = 0;
    },
  },
};
</script>

<style lang="less" scoped>
.back-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    width: 15px;
    margin-right: 5px;
  }
}
.select-box {
  margin-top: 15px;
  position: relative;
  img {
    position: absolute;
    width: 50px;
    top: -15px;
    margin-left: 10px;
  }
  .btn {
    position: absolute;
    right: 10px;
  }
}
._TableBox {
  margin-top: 10px;
}
/deep/ .el-dialog__body .select .el-input {
  width: 300px !important;
}
.inp {
  display: flex;
  margin-top: 10px;
  > span {
    margin-right: 10px;
    padding: 10px 0;
  }
  .draw {
    width: 300px;
    padding: 10px 0;
    > span {
      display: inline-block;
      width: 100px;
      text-align: center;
    }
  }
  .draw1 {
    background-color: white;
    box-shadow: 0px 6px 10px -6px silver;
  }
  .draw2 {
    display: flex;
    background-color: #f8f9fd;
    margin-top: 5px;
    .operating {
      display: flex;
      justify-content: center;
      width: 100px;
      /deep/input {
        width: 40px !important;
      }
    }
  }
}
</style>